(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-bet-button.js') >= 0) return;  svs.modules.push('/components/marketplace/bet-proposal/assets/javascripts/bet-proposal-bet-button.js');
'use strict';

const _excluded = ["eventTypeId", "isDisabled", "outcome", "outcome", "eventNumber", "onSelect"];
function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const NONE = 0;
const ADDING = 1;
const REMOVING = 2;
const CLICK_ACTIVE = 3;
const THRESHOLD = 5;
const CLASS_HOOK = 'js-bet-btn-marketplace';
const {
  ReactButtonBet
} = svs.components;
const {
  animatedCircle: animatedCircleMockable
} = svs.components.marketplace;
const {
  helpers
} = svs.components.marketplace.betProposal;
class BetProposalBetButton extends React.PureComponent {
  constructor() {
    var _this;
    super(...arguments);
    _this = this;
    _defineProperty(this, "_editingMode", NONE);
    _defineProperty(this, "_isTouchMoveRetrieved", false);
    _defineProperty(this, "_startX", 0);
    _defineProperty(this, "_selectedOutcomes", []);
    _defineProperty(this, "handleTouchStart", event => {
      clearTimeout(this._forbidClickTimeout);
      if (this._editingMode !== NONE) {
        return;
      }
      this._forbidClick = true;
      const {
        clientX
      } = event.touches[0];
      this._startX = clientX;
      const {
        isSelected
      } = this.props.outcome;
      this._editingMode = isSelected ? REMOVING : ADDING;
      this.ref.current.addEventListener('touchmove', this._preventScrollIfThresholdIsInEditingMode);
    });
    _defineProperty(this, "handleTouchMove", event => {
      if (this._editingMode !== ADDING && this._editingMode !== REMOVING) {
        return;
      }
      const {
        clientX,
        clientY
      } = event.touches[0];
      const {
        outcome: {
          outcomeNumber: thisOutcomeNumber
        },
        onSelect,
        eventNumber: thisEventNumber
      } = this.props;

      if (Math.abs(clientX - this._startX) > THRESHOLD) {
        if (!this._isOutcomeNumberInSelectionList(thisOutcomeNumber)) {
          onSelect({
            eventNumber: thisEventNumber,
            outcomeNumber: thisOutcomeNumber
          });
          this._selectedOutcomes.push(thisOutcomeNumber);
        }
        const targetBtn = BetProposalBetButton.getBetButtonTargetAtCoords(clientX, clientY);
        if (targetBtn) {
          const outcomeNumber = Number(targetBtn.dataset.outcomeNumber);
          const eventNumber = Number(targetBtn.dataset.eventNumber);
          const isSelected = Boolean(targetBtn.dataset.isSelected);
          const expectedSelectionMode = isSelected ? REMOVING : ADDING;
          if (eventNumber === thisEventNumber && expectedSelectionMode === this._editingMode && !this._isOutcomeNumberInSelectionList(outcomeNumber)) {
            onSelect({
              eventNumber,
              outcomeNumber: Number(outcomeNumber)
            });
            this._selectedOutcomes.push(outcomeNumber);
          }
        }
        this._isTouchMoveRetrieved = true;
      }
    });
    _defineProperty(this, "handleTouchEnd", function () {
      _this._editingMode !== CLICK_ACTIVE && _this._finishTouchOrClick(...arguments);
      _this._forbidClickTimeout = setTimeout(() => {
        _this._forbidClick = false;
      }, 400);
    });
    _defineProperty(this, "handleClick", function () {
      if (_this._editingMode === NONE && !_this._forbidClick) {
        _this._editingMode = CLICK_ACTIVE;
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }
        _this._finishTouchOrClick(args);
      }
    });
    _defineProperty(this, "_finishTouchOrClick", () => {
      !this._isTouchMoveRetrieved && this.props.onSelect({
        outcomeNumber: this.props.outcome.outcomeNumber,
        eventNumber: this.props.eventNumber,
        editingMode: this._editingMode
      });
      this._isTouchMoveRetrieved = false;
      this._selectedOutcomes = [];
      this._startX = 0;
      this.ref.current.removeEventListener('touchmove', this._preventScrollIfThresholdIsInEditingMode);
      requestIdleCallback(() => {
        this._editingMode = NONE;
      });
    });
    _defineProperty(this, "_isOutcomeNumberInSelectionList", outcomeNumber => this._selectedOutcomes.indexOf(outcomeNumber) !== -1);
    _defineProperty(this, "_preventScrollIfThresholdIsInEditingMode", event => this._editingMode !== NONE && event.preventDefault());
    _defineProperty(this, "ref", React.createRef());
  }
  static getBetButtonTargetAtCoords(clientX, clientY) {
    const target = document.elementFromPoint(clientX, clientY);
    return target && target.classList.contains(CLASS_HOOK) ? target : null;
  }
  render() {
    const _this$props = this.props,
      {
        eventTypeId,
        isDisabled,
        outcome: {
          description,
          isSelected,
          outcomeNumber
        },
        outcome,
        eventNumber,
        onSelect
      } = _this$props,
      props = _objectWithoutProperties(_this$props, _excluded);
    return React.createElement(ReactButtonBet, _extends({
      className: "btn-bet-marketplace qa-betbutton f-capitalize ".concat(CLASS_HOOK),
      "data-event-number": eventNumber,
      "data-is-selected": isSelected ? 'true' : '',
      "data-outcome-number": outcomeNumber,
      disabled: isDisabled,
      label: helpers.getOutcomeLabel({
        description,
        outcomeNumber,
        eventTypeId
      }),
      onClick: onSelect && this.handleClick,
      onTouchEnd: onSelect && this.handleTouchEnd,
      onTouchMove: onSelect && this.handleTouchMove,
      onTouchStart: onSelect && this.handleTouchStart,
      ref: this.ref,
      selected: isSelected,
      size: "300",
      type: "button"
    }, props), React.createElement(animatedCircleMockable.AnimatedCircle, {
      animate: true,
      isVisible: isSelected
    }));
  }
}
BetProposalBetButton.defaultProps = {
  eventTypeId: null,
  isDisabled: false
};
setGlobal('svs.components.marketplace.betProposal.BetProposalBetButton', BetProposalBetButton);

 })(window);